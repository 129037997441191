import React from 'react';
import AddLikesContainer from "../containers/add-likes-container/add-likes-container";
import './app.sass'
import {Route, Switch} from "react-router";
import Home from "../containers/home-container/home-container";
import Header from "../components/header/header";

function App() {
  return (
      <div className={"wrapper"}>
          <Header/>
          <Switch>
              <Route
                  path={"/"}
                  exact={true}
                  render={() => <Home/>}
              />
              <Route
                  path={"/likes-:count"}
                  isExact={true}
                  render={({match}) => <AddLikesContainer count={match.params.count} type={"settings"}/>}
              />
              <Route
                  path={"/likes"}
                  isExact={true}
                  render={() => <AddLikesContainer type={"settings"}/>}
              />
              <Route
                  path={"/profile"}
                  isExact={true}
                  render={({match}) => <AddLikesContainer count={match.params.count} type={"profile"}/>}
              />
          </Switch>
      </div>
  );
}

export default App;
