import React from 'react'
import "./error-block.sass"

export const ErrorBlock = ({error}) => {
    console.log(error)
    return(
        <div className="error-block">
            <div className={"error-title"}><i className="fas fa-exclamation-circle"></i> Error</div>
            <div className={"error-text"}>{error}</div>
        </div>
    )
}