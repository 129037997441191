import React from 'react'
import {Button} from "../../../helper-components/button/button";
import {countCost} from "../../../../helpers/count-cost";

export const PaymentSection = ({active, checkedPhotos, checkPhoto, likes, makeOrder}) => {
    const likesCount = (likes / checkedPhotos.length).toFixed(0);
    if(!active)
        return(
            <>
                <div className="hr"></div>
                <div className={`title h3 inactive`}>Summary & Payment</div>
            </>
        )

    return(
        <>
            <div className="hr"></div>
            <div className={`title h3 ${active? "active" : ""}`}>Summary & Payment</div>
            <div className="payment-section">
                <div className="select-account">Make sure that you've selected all of the posts you wanted.</div>
                <div className="payment-checked-container">
                    {
                        checkedPhotos.map((el, key) => {
                            return(
                                <div className="item" key={key}>
                                    <div className="img-box">
                                        <img src={el} className={"img"} alt=""/>
                                        <div className="likes">{likesCount} likes</div>
                                    </div>
                                    <div
                                        onClick={() => checkPhoto(el)}
                                        className="close">
                                        <i className="fas fa-times"></i>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="total">Your Total: <span className="count">{countCost(likes)}$</span></div>
                <Button
                    onClick={makeOrder}
                    value={<span>Pay Now</span>}
                    buttonLoader={false}
                />
            </div>

        </>
    )
}