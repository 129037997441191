import React from 'react'
import {countCost} from "../../../helpers/count-cost";
import "./item-package.sass"
import diamond from '../../../images/diamond.png'
import {withRouter} from "react-router";
import {Link} from "react-router-dom";

const ItemPackage = ({count, premium=false, history}) => {
    return(
        <div className={`item-package ${premium? "premium" : ""}`}>
            <div className="likes-count"><div className={"count"}>{count}</div> LIKES</div>
            <div className="desc">
                <div>Likes from Real Users</div>
                <div>No Password Required</div>
                <div>Start within 5 seconds</div>
                <div>24/7 Support</div>
            </div>
            <div className="price">${countCost(count)}</div>
            <Link
                to={`/likes-${count}`}
                className="button">
                Choose {premium ? <img src={diamond} alt=""/> : ""}
            </Link>
        </div>
    )
}

export default withRouter(ItemPackage)