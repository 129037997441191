import React from 'react'
import {countCost} from "../../../helpers/count-cost";

export const SelectPackage = ({className="", onChange=()=>{}, value=""}) => {
    return(
        <select className="input" value={value} onChange={onChange}>
            <option value="50">50 likes | ${countCost(50)}</option>
            <option value="100">100 likes | ${countCost(100)}</option>
            <option value="300">300 likes | ${countCost(300)}</option>
            <option value="500">500 likes | ${countCost(500)}</option>
            <option value="1000">1000 likes | ${countCost(1000)} </option>
            <option value="2500">2500 likes |${countCost(2500)} </option>
            <option value="5000">5000 likes | ${countCost(5000)}</option>
            <option value="10000">10000 likes | ${countCost(10000)}</option>
            <option value="20000">20000 likes | ${countCost(20000)}</option>
            <option value="50000">50000 likes | ${countCost(50000)}</option>
        </select>
    )
}