import React from 'react'
import "./header.sass"
import like from "../../images/like.png"
import {withRouter} from "react-router";
import {Link} from "react-router-dom";

const Header = () => {
    return(
        <header>
            <div className="wrap">
                <Link to={"/"} className="logo">Likes <img src={like} alt=""/></Link>
                <nav>
                    <div className="item">Blog</div>
                    <div className="item">Login</div>
                    <div className="item">About us</div>
                    <div className="item">Support</div>
                </nav>
            </div>
        </header>
    )
}

export default withRouter(Header)