import React, {useState} from 'react'
import Slider from '@material-ui/core/Slider';
import ItemPackage from "../item-package/item-package";
import "./slider-package.sass"
import crown from "../../../images/crown.png";

export const SliderPackage = () => {

    const [value, setValue] = useState(2500)

    return(
        <div className={"slider-container"}>
            <div className="slider-column">
                <div className="title">PREMIUM <img src={crown} alt=""/></div>
                <div className="text">If you want to get more than 1000 likes, you are able to buy PREMIUM package on
                    2500, 5000, 10000, 20000 and 50000 likes. By procuring more likes, you’ll not just be establishing
                    your online presence more, but get better discounts and deals in the process too!</div>
                <div className="slider">
                    <Slider
                        defaultValue={2500}
                        onChange={(e, value) => setValue(value)}
                        aria-labelledby="discrete-slider-restrict"
                        step={null}
                        valueLabelDisplay="auto"
                        max={50000}
                        marks={[
                            {value: 2500, label: '',},
                            {value: 5000, label: '',},
                            {value: 10000, label: '',},
                            {value: 20000, label: '',},
                            {value: 50000, label: '',},
                        ]}
                    />
                </div>
            </div>
            <div className="package-column">
                <ItemPackage count={value} premium={true}/>
            </div>
        </div>
    )
}