import * as axios from "axios";

let test=false;
if(process.env.NODE_ENV == 'production') test=false;

export const fetchPhotos = async( username ) => {

    if(test) return new Promise(resolve => setTimeout(() => resolve( {data:fakeData} ), 500));

    let options = {
        method: "post",
        url: `https://www.social-media.in.ua/api/likes/getImages.php`,
        data: {username}
    };
    const res = await axios(options);
    return res
};

export const fetchMorePhotos = async(data) => {
    if(test) return new Promise(resolve => setTimeout(() => resolve( {data:fakeData} ), 500));
    let options = {
        method: "post",
        url: `https://www.social-media.in.ua/api/likes/getImages.php`,
        data
    };
    const res = await axios(options);
    return res;
}

export const fetchOrder = async() => {
    // if(test) return new Promise(resolve => setTimeout(() => resolve( {data:fakeData} ), 500));
    const data = {

        orders: [
            {
                "orderLink" : "https://www.instagram.com/p/CB8p59phgTu/",
                "likesCount" : 20
            },
            {
                "orderLink" : "https://www.instagram.com/p/CB3gVwVBBBq/",
                "likesCount" : 22
            }
        ],
        category: "likes"
    }
    let options = {
        method: "post",
        url: `https://www.social-media.in.ua/api/like/order`,
        headers: {
            Authorization: 'Bearer c-bRtyPbRNXDp413oHnPgv0q56rlQVwa'
        },
        data
    };
    const res = await axios(options);
    return res;
}

const error = {
    code: -1,
    data: "Instagram username is incorrect"
}

const fakeData = {
    code: 0,
    data: {
        medias: [
            {
                fullLink: "https://instagram.fhel3-1.fna.fbcdn.net/v/t51.2885-15/e35/s1080x1080/106618729_1111345415915458_4891277117089772649_n.jpg?_nc_ht=instagram.fhel3-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=fuN55v6uB2YAX_eAiGw&oh=8a1593015e66007c9bbe4aca86f97952&oe=5F2D9F21",
                likesCount: 1271365,
                orderLink: "https://www.instagram.com/p/CCMA5vlA2vA/",
                thumbLink: "https://instagram.fhel3-1.fna.fbcdn.net/v/t51.2885-15/sh0.08/e35/s640x640/106618729_1111345415915458_4891277117089772649_n.jpg?_nc_ht=instagram.fhel3-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=fuN55v6uB2YAX_eAiGw&oh=f775916f92d6fc6ebb78bfa3f5339b15&oe=5F2BFFCA",
            },
            {
                fullLink: "https://instagram.fhel3-1.fna.fbcdn.net/v/t51.2885-15/e35/106297624_995172814247428_2292166964937826884_n.jpg?_nc_ht=instagram.fhel3-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=n-TivVC12tkAX_QHSmM&oh=76e2432c24b86531afdc0c42e033f892&oe=5F068FA6",
                likesCount: 502833,
                orderLink: "https://www.instagram.com/p/CCGFE68Adns/",
                thumbLink: "https://instagram.fhel3-1.fna.fbcdn.net/v/t51.2885-15/sh0.08/e35/s640x640/106297624_995172814247428_2292166964937826884_n.jpg?_nc_ht=instagram.fhel3-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=n-TivVC12tkAX_QHSmM&oh=b37c9fd0000be18aff380450e39e9025&oe=5F06F3A0"
            },
            {
                fullLink: "https://instagram.fhel3-1.fna.fbcdn.net/v/t51.2885-15/e35/s1080x1080/106042414_1151938085179943_7106443346831967064_n.jpg?_nc_ht=instagram.fhel3-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=z0pkaM4Id0gAX-Ps3AZ&oh=b8904d3f8eb2af43f7771b29ae9cc3bf&oe=5F2CF3F8",
                likesCount: 373304,
                orderLink: "https://www.instagram.com/p/CB-gvXDg3Rw/",
                thumbLink: "https://instagram.fhel3-1.fna.fbcdn.net/v/t51.2885-15/sh0.08/e35/s640x640/106042414_1151938085179943_7106443346831967064_n.jpg?_nc_ht=instagram.fhel3-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=z0pkaM4Id0gAX-Ps3AZ&oh=a6467e351dfd0db48aa2cc58ba566e25&oe=5F2D769F"
            },
            {
                fullLink: "https://instagram.fhel3-1.fna.fbcdn.net/v/t51.2885-15/e35/s1080x1080/106618729_1111345415915458_4891277117089772649_n.jpg?_nc_ht=instagram.fhel3-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=fuN55v6uB2YAX_eAiGw&oh=8a1593015e66007c9bbe4aca86f97952&oe=5F2D9F21",
                likesCount: 1271365,
                orderLink: "https://www.instagram.com/p/CCMA5vlA2vA/",
                thumbLink: "https://instagram.fhel3-1.fna.fbcdn.net/v/t51.2885-15/sh0.08/e35/s640x640/106618729_1111345415915458_4891277117089772649_n.jpg?_nc_ht=instagram.fhel3-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=fuN55v6uB2YAX_eAiGw&oh=f775916f92d6fc6ebb78bfa3f5339b15&oe=5F2BFFCA",
            },
            {
                fullLink: "https://instagram.fhel3-1.fna.fbcdn.net/v/t51.2885-15/e35/s1080x1080/106618729_1111345415915458_4891277117089772649_n.jpg?_nc_ht=instagram.fhel3-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=fuN55v6uB2YAX_eAiGw&oh=8a1593015e66007c9bbe4aca86f97952&oe=5F2D9F21",
                likesCount: 1271365,
                orderLink: "https://www.instagram.com/p/CCMA5vlA2vA/",
                thumbLink: "https://instagram.fhel3-1.fna.fbcdn.net/v/t51.2885-15/sh0.08/e35/s640x640/106618729_1111345415915458_4891277117089772649_n.jpg?_nc_ht=instagram.fhel3-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=fuN55v6uB2YAX_eAiGw&oh=f775916f92d6fc6ebb78bfa3f5339b15&oe=5F2BFFCA",
            },
            {
                fullLink: "https://instagram.fhel3-1.fna.fbcdn.net/v/t51.2885-15/e35/s1080x1080/106618729_1111345415915458_4891277117089772649_n.jpg?_nc_ht=instagram.fhel3-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=fuN55v6uB2YAX_eAiGw&oh=8a1593015e66007c9bbe4aca86f97952&oe=5F2D9F21",
                likesCount: 1271365,
                orderLink: "https://www.instagram.com/p/CCMA5vlA2vA/",
                thumbLink: "https://instagram.fhel3-1.fna.fbcdn.net/v/t51.2885-15/sh0.08/e35/s640x640/106618729_1111345415915458_4891277117089772649_n.jpg?_nc_ht=instagram.fhel3-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=fuN55v6uB2YAX_eAiGw&oh=f775916f92d6fc6ebb78bfa3f5339b15&oe=5F2BFFCA",
            },
            {
                fullLink: "https://instagram.fhel3-1.fna.fbcdn.net/v/t51.2885-15/e35/s1080x1080/106618729_1111345415915458_4891277117089772649_n.jpg?_nc_ht=instagram.fhel3-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=fuN55v6uB2YAX_eAiGw&oh=8a1593015e66007c9bbe4aca86f97952&oe=5F2D9F21",
                likesCount: 1271365,
                orderLink: "https://www.instagram.com/p/CCMA5vlA2vA/",
                thumbLink: "https://instagram.fhel3-1.fna.fbcdn.net/v/t51.2885-15/sh0.08/e35/s640x640/106618729_1111345415915458_4891277117089772649_n.jpg?_nc_ht=instagram.fhel3-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=fuN55v6uB2YAX_eAiGw&oh=f775916f92d6fc6ebb78bfa3f5339b15&oe=5F2BFFCA",
            },
            {
                fullLink: "https://instagram.fhel3-1.fna.fbcdn.net/v/t51.2885-15/e35/s1080x1080/106618729_1111345415915458_4891277117089772649_n.jpg?_nc_ht=instagram.fhel3-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=fuN55v6uB2YAX_eAiGw&oh=8a1593015e66007c9bbe4aca86f97952&oe=5F2D9F21",
                likesCount: 1271365,
                orderLink: "https://www.instagram.com/p/CCMA5vlA2vA/",
                thumbLink: "https://instagram.fhel3-1.fna.fbcdn.net/v/t51.2885-15/sh0.08/e35/s640x640/106618729_1111345415915458_4891277117089772649_n.jpg?_nc_ht=instagram.fhel3-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=fuN55v6uB2YAX_eAiGw&oh=f775916f92d6fc6ebb78bfa3f5339b15&oe=5F2BFFCA",
            },
            {
                fullLink: "https://instagram.fhel3-1.fna.fbcdn.net/v/t51.2885-15/e35/s1080x1080/106618729_1111345415915458_4891277117089772649_n.jpg?_nc_ht=instagram.fhel3-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=fuN55v6uB2YAX_eAiGw&oh=8a1593015e66007c9bbe4aca86f97952&oe=5F2D9F21",
                likesCount: 1271365,
                orderLink: "https://www.instagram.com/p/CCMA5vlA2vA/",
                thumbLink: "https://instagram.fhel3-1.fna.fbcdn.net/v/t51.2885-15/sh0.08/e35/s640x640/106618729_1111345415915458_4891277117089772649_n.jpg?_nc_ht=instagram.fhel3-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=fuN55v6uB2YAX_eAiGw&oh=f775916f92d6fc6ebb78bfa3f5339b15&oe=5F2BFFCA",
            },
        ],
        userInfo: {
            biography: "📩  hello@ivlei.com↵Commercial 👉🏼 +7-995-507-59-72 Василий  ↵НОВЫЙ ВЛОГ ААааа‼️↵по ссылке👇🏽👇🏽👇🏽",
            endCursor: "QVFCY0MwZ3V3NUs5bXN6OV9XUWRsVmdXTF9ZV2lfUG4xbXZOOXVydlVmaFkwdjNZSDM0VlcySFM1NVhONWlqb1RHVzNtLV9ya0R0Y3NZXzMxQXg5QVFxaQ==",
            followers: 17355520,
            following: 32,
            fullName: "НАСТЯ ИВЛЕЕВА",
            id: 46829718,
            image: "https://instagram.fhel3-1.fna.fbcdn.net/v/t51.2885-19/s320x320/106194417_1331727583833204_7737590994136608250_n.jpg?_nc_ht=instagram.fhel3-1.fna.fbcdn.net&_nc_ohc=MqKdHlemvl8AX8X0TRT&oh=55ceda28f07fbcbff987e4976c542302&oe=5F2C4CEF",
            isPrivate: false,
            mediaCount: 733,
            username: "_agentgirl_"
        }
    }
}