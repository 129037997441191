import React from 'react'
import "../../../../containers/add-likes-container/add-likes.sass"
import {ErrorBlock} from "../../../helper-components/error-block/error-block";
import {Button} from "../../../helper-components/button/button";
import {SelectPackage} from "../../../helper-components/select-package/select-package";

const SettingsSection = ({loadAccount, setLikes, likes, setName, name, error, buttonLoader}) => {
    return (
        <div className={"settings-section"}>
            <div className="title h3">Instagram Account</div>
            <div className="select-account">Select your Instagram account</div>
            <form onSubmit={(e) => {e.preventDefault(); loadAccount(name)}}>
                <div className="input-wrapper">
                    <div className="icon"></div>
                    <input
                        type="text"
                        required={true}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="input"
                        placeholder={"Your Instagram Name"}/>
                </div>
                <Button value={"Select Account"} buttonLoader={buttonLoader} type={"submit"}/>
                {
                    error? <ErrorBlock error={error}/> : ""
                }

            </form>
            <div className="hr"></div>
            <div className="title h3">Package</div>
            <div className="select-account">
                Select a suitable package.
                You'll be able to spread likes throughout your images in the next step.
            </div>
            <div className="input-wrapper">
                <SelectPackage value={likes} onChange={(e) => setLikes(e.target.value)}/>
            </div>
        </div>
    )
}

export default SettingsSection