import React from 'react'
import {Link} from "react-router-dom";
import heart from "../../../../images/heart.png"
import {Redirect} from "react-router";
import {Button} from "../../../helper-components/button/button";
import {PaymentSection} from "../payment-section/payment-section";
import {SelectPackage} from "../../../helper-components/select-package/select-package";

const AddLikesProfile = ({profile, likes, checkPhoto, checkedPhotos=[], setLikes, loadMorePhotos, medias, buttonLoader}) => {
    if(!profile) return <Redirect to={"/likes"}/>;
    const likesCount = (likes / checkedPhotos.length).toFixed(0);

    return (
        <div className={"profile-section"}>
            <div className="row profile">
                <div className="profile-info">
                    <div className="img">
                        <img src={profile.image} alt="image"/>
                    </div>
                    <span className="name title h3">
                        {profile.username}
                        <div className="full-name">({profile.fullName})</div>
                    </span>
                </div>
                <Link to={"/likes"} className="button" onClick={()=>{}}>Change Account</Link>
            </div>

            <div className="media-box">
                <div className="media">Followers: <span className={"media-value"}>{profile.followers}</span></div>
                <div className="media">Publications: <span className={"media-value"}>{profile.mediaCount}</span></div>
                <div className="media">Following: <span className={"media-value"}>{profile.following}</span></div>
            </div>
            <div className="hr"></div>
            <div className="row package">
                <div className={"title  h3"}>Checked package:</div>
                <SelectPackage value={likes} onChange={(e) => setLikes(e.target.value)}/>
            </div>
            <div className="hr"></div>
            <div className="title h3">Select Posts</div>
            <div className="select-account">Please select posts. Once you're done, scroll down to the 'Summary & Payment'
                section below.</div>
            <div className="gallery">
                {
                    medias.map((el, ind) => {
                        const incl = checkedPhotos.includes(el.thumbLink);
                        return(
                            <div
                                className={`gallery-item ${incl? 'checked' : ""}`}
                                key = {ind}
                                onClick={() => checkPhoto(el.thumbLink)}>
                                {
                                    incl?
                                        <div className="checked-photo">{likesCount} ♥</div> : ""
                                }
                                <div className="img">
                                    <img src={el.thumbLink} alt="image"/>
                                </div>
                                <div className="desc">
                                    <img src={heart} alt="heart" className={"heart"}/>
                                    {el.likesCount}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <Button
                value={<span>Load More</span>}
                buttonLoader={buttonLoader}
                onClick={loadMorePhotos}
            />
        </div>
    )
}

export default AddLikesProfile;