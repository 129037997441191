import React from 'react'
import "./button.sass"
import {Spinner} from "../spinner/spinner";

export const Button = (props) => {
    const {buttonLoader=false, onClick=()=>{}, value="", className="", type="text"} = props;

    return(
        <div className={`button-container`}>
            <button
                type={type}
                className={`button ${buttonLoader? 'loader' : ''} ${className}`}
                onClick={onClick}>
                <Spinner/> <span className={'button-value'}>{value}</span>
            </button>
        </div>
    )
}