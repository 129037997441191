import React, {useState, useMemo, useEffect} from 'react'
import "./add-likes.sass"
import {fetchMorePhotos, fetchPhotos, fetchOrder} from "../../servers/servers";
import SettingsSection from "../../components/pages/add-likes-page/settings-section/settings-section";
import {withRouter} from "react-router";
import AddLikesProfile from "../../components/pages/add-likes-page/profile-section/add-likes-profile";
import {PaymentSection} from "../../components/pages/add-likes-page/payment-section/payment-section";

const AddLikesContainer = ({count, history, type}) => {
    const [likes, setLikes] = useState(50);
    const [checkedPhotos, setCheckedPhotos] = useState([]);
    const [profile, setProfile] = useState(false);
    const [medias, setMedias] = useState([]);
    const [name, setName] = useState("");
    const [error, setError] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);

    useEffect(() => {
        // loadAccount("_agentgirl_");
        if(count){
            setLikes(count);
            history.push("likes");
        }
    }, []);

    const checkErrors = (data={}) => {
        const {data:{userInfo:{isPrivate}={}}={}} = data;
        if(data.code === -1) {setError(data.data); return false}
        if(isPrivate) {
            setError("Your account is private, kindly change the account to public and try again!");
            return false
        }
        return true;
    };

    const loadAccount = (value) => {
        setButtonLoader(true);
        setError(false);
        fetchPhotos(value)
            .then(({data={}}) => {
                const {data:{userInfo, medias}={}} = data;
                setButtonLoader(false);
                if(!checkErrors(data)) return;
                setProfile(userInfo);
                setMedias(medias);
                history.push("profile")
            })
    };

    const loadMorePhotos = () => {
        const {username, id, endCursor} = profile;
        const data = {username,id,endCursor};
        setButtonLoader(true);
        fetchMorePhotos(data)
            .then(({data={}}) => {
                const {data:{userInfo, medias:newMedias}={}} = data;
                setButtonLoader(false);
                if(!checkErrors(data)) return;
                setProfile({...profile, ...userInfo});
                setMedias([...medias, ...newMedias]);
                history.push("profile")
            })
    };

    const checkPhoto = (id) => {
        let array = [...checkedPhotos];
        if(array.length >= 15) {return alert("You can select at most 15 posts")}
        if(likes / array.length < 10) {return alert("You can’t select less than 10 likes per photo")}
        if(checkedPhotos.includes(id)){
            array.splice(array.indexOf(id), 1);
        } else array = [...checkedPhotos, id];
        setCheckedPhotos(array)
    };

    const makeOrder = () => {
        fetchOrder().then((data) => {
            console.log(data)
        })
    }

    return(
        <div className={"add-likes-settings"}>
            <div className="add-container">
                {
                    type === 'profile' && !error?
                        <>
                            <AddLikesProfile
                                profile={profile}
                                medias={medias}
                                buttonLoader={buttonLoader}
                                likes={likes}
                                checkPhoto={checkPhoto}
                                loadMorePhotos={loadMorePhotos}
                                checkedPhotos={checkedPhotos}
                                setLikes={setLikes}/>
                            <PaymentSection
                                likes={likes}
                                checkPhoto={checkPhoto}
                                active={checkedPhotos.length}
                                checkedPhotos={checkedPhotos}
                                makeOrder={makeOrder}
                            />
                        </>
                    :
                        <SettingsSection
                            name={name}
                            error={error}
                            buttonLoader={buttonLoader}
                            setName={setName}
                            likes={likes}
                            setLikes={setLikes}
                            loadAccount = {loadAccount}/>
                }

            </div>
        </div>
    )
}

export default withRouter(AddLikesContainer)