import React from 'react'
import "./home-container.sass"
import ItemPackage from "./item-package/item-package";
import {SliderPackage} from "./slider-package/slider-package";
import aboutImg from "../../images/about.png"
import {countCost} from "../../helpers/count-cost";
import {Link} from "react-router-dom";

const Home = () => {
    return(
        <div className={"home-page"}>
            <div className="init-section section">
                <h1>Buy Instagram likes, followers & views</h1>
            </div>
            <div className="about-section section">
                <div className="wrap">
                    <div className="section-title">About Us</div>
                    <div className="about-container">
                        <div className="about-content">
                            <div className="title">Why should you use our site?</div>
                            <div className="text">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam amet architecto at,
                                beatae, debitis ea eos hic iusto magnam non nulla odit pariatur porro quas, sed tempora
                                totam voluptas voluptate. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                Laboriosam laborum minima placeat recusandae tempora.
                            </div>
                            <Link to={`/likes-300`} className="button">Try 300 Likes for {countCost(300)}$</Link>
                        </div>
                        <div className="about-image">
                            <img src={aboutImg} alt="likes" className="img"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"hr hr-home"}/>
            <div className="package-section section">
                <div className="wrap">
                    <div className="section-title">Check your package</div>
                    <div className="package-container">
                        <ItemPackage count={50}/>
                        <ItemPackage count={100}/>
                        <ItemPackage count={300}/>
                        <ItemPackage count={500}/>
                        <ItemPackage count={1000}/>
                    </div>
                </div>
            </div>
            <div className="package-section premium-section section">
                <div className="wrap">
                    <div className="section-title">Or you can buy one of the premium packages</div>
                    <div className="package-container">
                        <SliderPackage/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
